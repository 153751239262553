// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

// external libs
import I18n from 'i18n-react'
import IdleTimer from 'react-idle-timer'

// containers
import HomepageScreen from './HomepageScreen'
import CarsScreen from './CarsScreen'
import ListCarScreen from './ListCarScreen'
import ProductScreen from './ProductScreen'
import FilterScreen from './FilterScreen'
import QueryScreen from './QueryScreen'

// redux
import GeneralActions from '../Redux/GeneralRedux'
import StateMachineActions from '../Redux/StateMachineRedux'

// components
import BottomBanner from '../Components/BottomBanner'
import Header from '../Components/Header'

// language
import cs from '../Translations/cs.json'
import sk from '../Translations/sk.json'

// styles
import '../styles/core.scss'
import './Styles/RootStyles.scss'

type Props = {
  breadCrumb: Array,
  debug: boolean,
  fetching: boolean,
  history: Object,
  language: string,
  nextState: Object,
  startStateMachine: () => void,
  onChangeRedirectUrl: () => void,
  steps: Array<Object>,
  lastValidStep: number,
  location: Object,
  resetAll: () => void
}

class RootScreen extends React.Component<Props> {
  componentWillMount() {
    if (this.props.language === 'cs') {
      I18n.setTexts(cs)
    } else {
      I18n.setTexts(sk)
    }
    this.checkAccessToStep()
    this.props.startStateMachine()
  }

  componentDidMount() {
    if (window.location.pathname !== '/') {
      this.props.history.push('/')
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.nextState && nextProps.nextState !== '' && `/${nextProps.nextState}` !== nextProps.location.pathname) {
      this.props.onChangeRedirectUrl('')
      nextProps.history.push(nextProps.nextState)
    }
    if (nextProps.language !== this.props.language) {
      if (nextProps.language === 'cs') {
        I18n.setTexts(cs)
      } else {
        I18n.setTexts(sk)
      }
    }
  }

  checkAccessToStep = () => {
    for (let index = (this.props.lastValidStep + 1); index < this.props.steps.length; index++) {
      if (this.props.location.pathname.indexOf(this.props.steps[index].key) >= 0) {
        this.props.onChangeRedirectUrl(this.props.steps[this.props.lastValidStep].url)
        break
      }
    }
  }

  onIdleReset = () => {
      this.props.resetAll()
      this.props.history.push('/')
  }

  render () {
    return (
      <div className='body-full'>
        <Route
          exact
          path='/:next'
          render={() => <Header
            breadCrumb={this.props.breadCrumb}
          />}
        />
        <Switch>
          <Route
            component={HomepageScreen}
            exact
            path='/'
          />
          <Route
            component={CarsScreen}
            path='/cars'
          />
          <Route
            component={ListCarScreen}
            path='/list'
          />
          <Route
            component={FilterScreen}
            path='/fuel'
          />
          <Route
            component={FilterScreen}
            path='/gasyear'
          />
          <Route
            component={FilterScreen}
            path='/gaschange'
          />
          <Route
            component={FilterScreen}
            path='/oilchange'
          />
          <Route
            component={FilterScreen}
            path='/oilyear'
          /><Route
            component={FilterScreen}
            path='/dpf'
          />
          <Route
            component={ProductScreen}
            path='/product'
          />
          <Route
            component={QueryScreen}
            path='/query'
          />
        </Switch>
        <IdleTimer
          element={document}
          idleAction={this.onIdleReset}
          key='idleTimerShort'
          timeout={5000000}
        />
        {window.location.pathname !== '/query' && !this.props.modal &&
          <Route
            component={BottomBanner}
            exact
            path='/:next'
          />
        }
      </div>
    )
  }
}

RootScreen.contextTypes = {
  router: PropTypes.object
}

const mapStateToProps = state => ({
  fetching: state.general.fetching,
  steps: state.navigation.steps,
  lastValidStep: state.navigation.lastValidStep,
  language: state.admin.language,
  nextState: state.machine.nextState,
  breadCrumb: state.machine.breadCrumb,
  modal: state.machine.breadCrumb
})

const mapDispatchToProps = {
  onChangeRedirectUrl: GeneralActions.onChangeRedirectUrl,
  startStateMachine: StateMachineActions.startStateMachine,
  saveActualPath: StateMachineActions.saveActualPath,
  resetAll: GeneralActions.resetAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(RootScreen)
