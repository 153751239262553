// external libs
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  changePassword: ['password'],
  changePlace: ['place'],
  login: null,
  loginFailure: ['error'],
  openModal: ['index'],
  closeModal: null,
  setLanguage: ['language']
})

export const AdminType = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({ // eslint-disable-line
  admin: false,
  error: {},
  fetching: 0,
  modalLogin: false,
  modalIndex: 0,
  password: '',
  language: 'cs',
  place: {}
})

/* ------------- Reducers ------------- */

export const openModalR = state => {
  return state.merge({
    modalIndex: state.modalIndex + 1,
    modalLogin: state.modalIndex >= 9
  })
}

export const closeModalR = state =>
  state.merge({ modalLogin: false, admin: false, modalIndex: 0})

export const changePasswordR = (state, { password }) =>
  state.merge({ password })

export const changlePlaceR = (state, { place }) =>
  state.merge({ place })

export const loginR = state =>
  state.merge({ modalLogin: false, admin: true })

export const loginFailureR = (state, { error }) => {
  return state.merge({ password: '', error: error })
}

export const setLanguageR = (state, { language }) =>
  state.merge({ language })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_PASSWORD]: changePasswordR,
  [Types.CHANGE_PLACE]: changlePlaceR,
  [Types.LOGIN]: loginR,
  [Types.LOGIN_FAILURE]: loginFailureR,
  [Types.OPEN_MODAL]: openModalR,
  [Types.CLOSE_MODAL]: closeModalR,
  [Types.SET_LANGUAGE]: setLanguageR,
})
