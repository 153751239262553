// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// external libs
import I18n from 'i18n-react'

// lib
import { replaceAccent } from '../Lib/Utilities'

// redux
import StateMachineActions from '../Redux/StateMachineRedux'

// themes
import { Images } from '../Themes'

// styles
import './Styles/CarsScreenStyles.scss'

type Props = {
  mostUsedCar: Array<Object>,
  letter: string,
  stateMachine: Object,
  changeState: (string) => void,
}

class CarsScreen extends React.Component<Props, null> {

  goToFuel = (car) => { this.props.changeState(car) }

  render () {
    const transitions = this.props.stateMachine.transitions() || []
    return (
        <div className='brand-page'>
          <div className='container'>
            <div className='wrapper-car-logo'>
              {transitions.map((value, index) =>
                this.props.letter === '' && this.props.mostUsedCar.includes(value)
                  ? (
                    <div className='wrapper-image' key={value} onClick={() => { this.goToFuel(value) }}>
                      <img className={`image ${value}`} src={Images[value]} />
                    </div>
                  )
                  : this.props.letter === replaceAccent(value)[0]
                  ? (
                    <div className='wrapper-image' key={value} onClick={() => { this.goToFuel(value) }}>
                      <img className={`image ${value}`} src={Images[value]} />
                    </div>
                  )
                  : null
              )}
              <div className='divider' />
            </div>
            <div className='button-wrapper-car'>
              <Link className='none-underline' to='/list' >
                <div className='btn btn-danger'>
                  <span className='btn-text'>{I18n.translate('_general.list')}</span>
                </div>
              </Link>
              <div className='back-button'>
                <Link className='none-underline' to='/'>
                  <div className='btn btn-primary arrow-back'>
                    <span className='btn-text'>{I18n.translate('_general.back')}</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

const mapStateToProps = state => ({
  stateMachine: state.machine.stateMachine,
  letter: state.machine.letter,
  mostUsedCar: state.machine.mostUsedCar,
})

const mapDispatchToProps = {
  changeState: StateMachineActions.changeState,
  backStateMachine: StateMachineActions.backStateMachine,
}

export default connect(mapStateToProps, mapDispatchToProps)(CarsScreen)
