// external libs
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	onChangeIsMobile: ['isMobile'],
	onSetLastValidStep: ['key', 'url'],
	onSetNextPage: ['nextPage'],
	onSetReferrer: ['referrer'],
	onChangeWebType: ['webType'],
	onSetUtmParams: ['utmParams']
})

export const NavigationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const WEB_TYPES = {
	DEFAULT: 0,
	SRO: 1,
  SUR: 2,
  POV: 3,
}

export const INITIAL_STATE = Immutable({ // eslint-disable-line
	isMobile: false,
	lastValidStep: 0,
	nextPage: '',
	referrer: '',
	webType: WEB_TYPES.DEFAULT,
	steps: [
		{
			key: 'product',
			label: 'Product',
			url: '/'
		},
		{
			key: 'quotes',
			label: 'Quotes',
			url: ''
		},
		{
			key: 'calculator',
			label: 'Calculator',
			url: ''
		}
	],
	utmParams: {}
})

/* ------------- Reducers ------------- */

export const onChangeIsMobile = (state, { isMobile }) =>
	state.merge({ isMobile })

export const setNextPage = (state, { nextPage }) =>
	state.merge({ nextPage })

export const setReferrer = (state, { referrer }) =>
	state.merge({ referrer })

export const changeWebType = (state, { webType }) =>
	state.merge({ webType: Object.values(WEB_TYPES).indexOf(webType) !== -1 ? webType : WEB_TYPES.DEFAULT })

export const setLastValidStep = (state, { key, url }) => {
	let steps = state.steps.asMutable()
	let lastValidStep = state.lastValidStep
	steps = steps.map((step, index) => {
		if (step.key === key) {
			step = {
				...step,
				url
			}
			if (index > lastValidStep) {
				lastValidStep = index
			}
		}
		return step
	})
	return state.merge({ steps, lastValidStep })
}

export const setUtmParams = (state, { utmParams }) =>
	state.merge({ utmParams })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.ON_CHANGE_IS_MOBILE]: onChangeIsMobile,
	[Types.ON_SET_LAST_VALID_STEP]: setLastValidStep,
	[Types.ON_SET_NEXT_PAGE]: setNextPage,
	[Types.ON_SET_REFERRER]: setReferrer,
	[Types.ON_SET_UTM_PARAMS]: setUtmParams,
	[Types.ON_CHANGE_WEB_TYPE]: changeWebType,
})
