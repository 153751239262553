// external libs
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	onChangeDebugMode: ['debug'],
})

export const DebugTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({ // eslint-disable-line
	debug: false
})

/* ------------- Reducers ------------- */

export const onChangeDebugModeR = (state, { debug }) =>
	state.merge({ debug: !!debug })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.ON_CHANGE_DEBUG_MODE]: onChangeDebugModeR,
})
