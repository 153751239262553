// @flow
import React, { Component } from 'react'
import { Button } from 'reactstrap'

// external libs
import I18n from 'i18n-react/dist/i18n-react'
import Select from 'react-select'

// services
import PlacesShell from '../Services/PlacesShell.json'

// styles
import './Styles/ModalAdminStyles.scss'

// themes
import { Images } from '../Themes'

type Props = {
  closeModal: () => void,
  setLanguage: (string) => void,
  selectPlace: (Object) => void,
  onChange: (string) => void,
  onSubmit: () => void,
  language: string,
  place: Object,
  login: boolean,
  admin: boolean,
  password: string,
}
 export default class ModalAdmin extends Component<Props, null> {

   handleChangeLanguage = (lang) => { this.props.setLanguage(lang) }

   handleChangePlace = (selectedOption) => { this.props.selectPlace(selectedOption) }

   renderSelectPlaces = () => {
     const places = Object.values(PlacesShell[this.props.language])
     const options = places.map((value, index) => (
       { value: index, label: value }
     ))
     return (
       <Select
         name='form-field-name'
         onChange={this.handleChangePlace}
         options={options}
         value={this.props.place || options[0]}
       />
     )
   }

  render() {
    return (
      <div>
        {this.props.login &&
          <div className='modal-wrapper'>
            <div className='select-wrapper'>
              <div className='close-button' onClick={this.props.closeModal}>
                <img src={Images.closeButton} />
              </div>
              <div className='title-login'>
                <h3>{I18n.translate('_general.loginTitle')}</h3>
              </div>
              <label className='input-wrapper'>
                <span className='password'>{I18n.translate('_general.password')}</span>
                <input
                  className='input'
                  onChange={this.props.onChange}
                  value={this.props.password}
                />
              </label>
              <div
                className='btn btn-danger button-login'
                onClick={this.props.onSubmit}
              >
                {I18n.translate('_general.login')}
              </div>
            </div>
          </div>
        }
        {this.props.admin &&
          <div className='modal-wrapper'>
            <div className='select-wrapper'>
              <div className='close-button' onClick={this.props.closeModal}>
                <img className='mw-100' src={Images.closeButton} />
              </div>
              <h3 className='title-login'>{I18n.translate('_general.loginSuccess')}</h3>
              <div className='set-languages'>
                <div>{I18n.translate('_general.language')}</div>
               <div className='wrapper-button-admin'>
                 <Button
                   className='button-admin'
                   color={this.props.language === 'cs' ? 'success' : 'danger'}
                   onClick={() => {this.handleChangeLanguage('cs') }}
                 >
                   {I18n.translate('_general.cz')}
                 </Button>
                 <Button
                   className='button-admin'
                   color={this.props.language === 'sk' ? 'success' : 'danger'}
                   onClick={() => {this.handleChangeLanguage('sk') }}
                 >
                   {I18n.translate('_general.sk')}
                 </Button>
               </div>
              </div>
              <div className='place-wrapper'>
                <span>{I18n.translate('_general.place')}</span>
                {this.renderSelectPlaces()}
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
