import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import localForage from 'localforage'
import { GeneralTypes, INITIAL_STATE } from '../Redux/GeneralRedux'
import { INITIAL_STATE as MACHINE_INITIAL_STATE, stateMachine } from '../Redux/StateMachineRedux'

import immutableTransform from './immutablePersistanceTransform'

const persistConfig = {
  key: 'shell',
  storage: localForage,
  transforms: [immutableTransform()],
  whitelist: []
}

const resetReducer = (reducer, triggerAction) =>
  (state, action) => {
    if (action.type === triggerAction) {
      return reducer({
        general: state.general.merge({
          ...INITIAL_STATE,
          translations: state.general.translations
        }),
        machine: state.machine.merge({
          ...MACHINE_INITIAL_STATE,
          stateMachine: stateMachine()
        })
      }, { type: 'REDUCER_RESET' })
    }
    return reducer(state, action)
  }

const resetAll = reducer => (
  resetReducer(reducer, GeneralTypes.RESET_ALL)
)

export const makeRootReducer = () =>
  persistReducer(persistConfig,
    resetAll(combineReducers({
        general: require('../Redux/GeneralRedux').reducer,
        navigation: require('../Redux/NavigationRedux').reducer,
        debug: require('../Redux/DebugRedux').reducer,
        machine: require('../Redux/StateMachineRedux').reducer,
        admin: require('../Redux/AdminRedux').reducer
      })
    )
  )

export default makeRootReducer
