// external libs
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  onChangeError: ['error'],
  onChangeErrorRequest: ['error'],
  onSetFetching: ['fetching'],
  onStartFetching: null,
  onStopFetching: null,
  onChangeRedirectUrl: ['redirectUrl'],
  translationsRequestSuccess: ['translations'],
  errorTranslationsRequestSuccess: ['errorTranslations'],
  resetAll: null
})

export const GeneralTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({ // eslint-disable-line
  fetching: 0,
  redirectUrl: '',
  translations: {},
  errorTranslations: {},
  error: {},
})

/* ------------- Reducers ------------- */

export const changeError = (state, { error }) =>
  state.merge({ error })

export const changeErrorRequest = (state, { error }) =>
  state.merge({ error, fetching: 0 })

export const setFetching = (state, { fetching }) =>
  state.merge({ fetching })

export const startFetching = state =>
  setFetching(state, { fetching: state.fetching + 1 })

export const stopFetching = state =>
  setFetching(state, { fetching: state.fetching > 0 ? state.fetching - 1 : 0 })

export const onChangeRedirectUrlR = (state, { redirectUrl }) =>
  state.merge({ redirectUrl })

export const translationsRequestSuccessR = (state, { translations }) =>
  state.merge({ translations })

export const errorTranslationsRequestSuccessR = (state, { errorTranslations }) =>
  state.merge({ errorTranslations })

export const resetR = state => state

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_CHANGE_ERROR]: changeError,
  [Types.ON_SET_FETCHING]: setFetching,
  [Types.ON_START_FETCHING]: startFetching,
  [Types.ON_STOP_FETCHING]: stopFetching,
  [Types.ON_CHANGE_ERROR_REQUEST]: changeErrorRequest,
  [Types.ON_CHANGE_REDIRECT_URL]: onChangeRedirectUrlR,
  [Types.TRANSLATIONS_REQUEST_SUCCESS]: translationsRequestSuccessR,
  [Types.ERROR_TRANSLATIONS_REQUEST_SUCCESS]: errorTranslationsRequestSuccessR,
  [Types.RESET_ALL]: resetR,
})
