// @flow
import React, { Component } from 'react'

// external libs
import I18n from 'i18n-react'

// styles
import  './Styles/HeaderStyles.scss'

const titles = {
  '/cars': 'title./cars',
  '/list': 'title./cars',
  '/fuel': 'title./fuel',
  '/gaschange': 'title./gaschange',
  '/oilchange': 'title./oilchange',
  '/gasyear': 'title./gasyear',
  '/oilyear': 'title./oilyear',
  '/dpf': 'title./dpf',
  '/product': 'title./product',

}

export default class Header extends Component<null, null> {

  render () {
    return (
      <div className='header-container'>
        <div className='title-screen'>{I18n.translate(titles[window.location.pathname])}</div>
      </div>
    )
  }
}
