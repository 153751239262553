import React from 'react'
import { render as ReactRender } from 'react-dom'

const __DEV__ = true

import createStore from './store/createStore'
import AppContainer from './Containers/AppContainer'
import registerServiceWorker from './registerServiceWorker'


// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__ || {}
const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('app')

let render = () => {
	ReactRender(
    <AppContainer store={store} />,
    MOUNT_NODE
  )
}

// ========================================================
// Developer Tools Setup
// ========================================================
if (__DEV__) {
  if (window.devToolsExtension) {
    // window.devToolsExtension.open()
  }
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default

			ReactRender(<RedBox error={error} />, MOUNT_NODE)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        renderError(error)
      }
    }

    // Setup hot module replacement
    /* module.hot.accept('./routes/index', () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        render()
      })
    ) */
  }
}

// ========================================================
// Go!
// ========================================================
render()
registerServiceWorker()
