// @flow
import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

// external libs
import I18n from 'i18n-react'

// redux
import StateMachineActions from '../Redux/StateMachineRedux'

// themes
import { Images } from '../Themes'

// styles
import './Styles/ProductSceenStyles.scss'

type Props = {
  breadCrumb: Array<>,
  getProductInfo: Object,
  backStateMachine: () => void,
  products: Array<>,
  modal: boolean,
  showModal: () => void,
  productInfo: Object
}

class ProductScreen extends React.Component<Props, null> {

  openModal = (data) => {
    this.props.getProductInfo(data)
    this.props.showModal()
  }

  handleBack = () => this.props.backStateMachine()

  render() {
    const productInfo = this.props.productInfo
    return (
      <div className='final-page'>
        {!this.props.modal &&
        <div className='container-product'>
          <div className='wrapper'>
            <div className='info-wrapper'>
              <div className='text-wrapper'>
                <div className='product-title'>{I18n.translate('_general.product')}</div>
                {this.props.breadCrumb.map(value => (
                  <div className='info-text' key={value.value}>
                    {`${I18n.translate(`_general.selectInfo.${value.name.split('-')[0]}`)}: ${I18n.translate(`_general.${value.value.split('-')[0]}`)}`}
                  </div>
                ))}
              </div>
              <div className='plus-wrapper'>
                <img className='img-plus' src={Images.plus} />
                <span className='plus-text'>{I18n.translate('_general.plusText')}</span>
              </div>
            </div>
            <div className='product-wrapper-main'>
              {this.props.products.map(product =>
                <div className={`product-wrapper ${this.props.products.length > 1 ? 'margin-right' : ''}`} key={`product-${Math.random()}`} onClick={() => {
                  this.openModal(product)
                }}>
                  <h4>{I18n.translate(`_general.${product.name}`)}</h4>
                  <img alt={`${product.name}`} className='product-img' src={product.image}/>
                  <div className='product-text'>{product.product}</div>
                </div>
              )}
            </div>
          </div>
          <div className="text-center">
            <span className="missing-oil">{I18n.translate('_general.missingOil')}</span>
          </div>
          <div className='button-wrapper'>
            <div className='btn btn-primary arrow-back' onClick={this.handleBack}>
              <span className='btn-text'>{I18n.translate('_general.back')}</span>
            </div>
            <Link className='none-underline' to='/query'>
              <div className='btn btn-primary arrow-refresh'>
                <span className='btn-text'>{I18n.translate('_general.done')}</span>
              </div>
            </Link>
          </div>
        </div>
        }
        {this.props.modal && productInfo &&
        <div className='modal-wrapper'>
          <div className='wrapper-modal'>
            <div className='close-button' onClick={() => {
              this.openModal({})
            }}>
              <img src={Images.closeButton}/>
            </div>
            <div className='product-text'>
              <span>{productInfo.product}</span>
            </div>
            <div className='wrapper'>
              <div className='image-wrapper'>
                <div><img alt={`${productInfo.name}`} className='product-img' src={productInfo.image}/></div>
                <span className='product-name'>{I18n.translate(`_general.${productInfo.name}`)}</span>
              </div>
              <div className='wrapper-text'>
                <span className='select-product'>
                  {`${I18n.translate(`_general.selectProduct.${productInfo.name}`, {oil: productInfo.product})}`}
                </span>
                <span>{I18n.translate(`${productInfo.description}`)}</span>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  products: state.machine.products,
  modal: state.machine.modal,
  productInfo: state.machine.productInfo,
  breadCrumb: state.machine.breadCrumb,
})

const mapDispatchToProps = {
  showModal: StateMachineActions.showModal,
  getProductInfo: StateMachineActions.getProductInfo,
  backStateMachine: StateMachineActions.backStateMachine,
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductScreen)
