// @flow
import React, { Component } from 'react'

// external libs
import I18n from 'i18n-react'

// styles
import './Styles/BottomBannerStyles.scss'

export default class BottomBanner extends Component<null, null> {

  render() {
    let bannerConfig = {}
    switch (window.location.pathname) {
      case '/cars':
      case '/list':
        bannerConfig = { progresNummber: '20%', class: 'progress' }
        break
      case '/fuel':
        bannerConfig = { progresNummber: '40%', class: 'progress' }
        break
      case '/gaschange':
        bannerConfig = { progresNummber: '60%', class: 'progress' }
        break
      case '/gasyear':
        bannerConfig = { progresNummber: '80%', class: 'progress' }
        break
      case '/dpf':
        bannerConfig = { progresNummber: '50%', class: 'progress' }
        break
      case '/oilyear':
        bannerConfig = { progresNummber: '50%', class: 'progress' }
        break
      case '/product':
        bannerConfig = { progresNummber: '100%', class: 'progress done' }
        break
    }
    return (
      <div className='status'>
        <div className='state' style={{width: bannerConfig.progresNummber}}>&nbsp;</div>
        <span className={bannerConfig.class}>
          {window.location.pathname === '/product'
            ? I18n.translate('_general.finishProduct')
            : bannerConfig.progresNummber
          }
        </span>
      </div>
    )
  }
}
