// external libs
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import StateMachine from '../Lib/StateMachine'

// services
import product from '../Services/product.json'

export const stateMachine = () => {
  const events = []
  product.map((val) => {
    events.push({name: val.name, from: 'cars', to: 'fuel-' + val.name})
        //GAS
      if (val.gasLonglife || val.gasNa) {
        events.push({name: 'gas-' + val.name, from: 'fuel-' + val.name, to: 'gaschange-' + val.name})
      } else {
        events.push({name: 'gas-' + val.name, from: 'fuel-' + val.name, to: 'gasyear-' + val.name})
      }
        events.push({name: 'standard-' + val.name, from: 'gaschange-' + val.name, to: 'gasyear-' + val.name})
      if (val.gasYop1995) {
        events.push({name: 'gasyopb1995-' + val.name, from: 'gasyear-' + val.name, to: val.gasYop1995})
      }
      if (val.gasYopa1995) {
        events.push({name: 'gasyopa1995-' + val.name, from: 'gasyear-' + val.name, to: val.gasYopa1995})
      }
      if (val.gasYop2004) {
        events.push({name: 'gasyop2004-' + val.name, from: 'gasyear-' + val.name, to: val.gasYop2004})
      }
      if (val.gasYopa2004) {
        events.push({name: 'gasyopa2004-' + val.name, from: 'gasyear-' + val.name, to: val.gasYopa2004})
      }
      if (val.gasYopa2014) {
        events.push({name: 'gasyopa2014-' + val.name, from: 'gasyear-' + val.name, to: val.gasYopa2014})
      }      
      if (val.gasLonglife) {
        events.push({name: 'gaslonglife-' + val.name, from: 'gaschange-' + val.name, to: val.gasLonglife})
      }
      if (val.gasNa) {
        events.push({name: 'gasna-' + val.name, from: 'gaschange-' + val.name, to: val.gasNa})
      }
        //OIL
      if (val.oilDpfYes || val.oilDpfNa) {
        events.push({name: 'oil-' + val.name, from: 'fuel-' + val.name, to: 'dpf-' + val.name})
      } else {
        events.push({name: 'oil-' + val.name, from: 'fuel-' + val.name, to: 'oilyear-' + val.name})
      }
      if (val.oilDpfNoLonglife || val.oilDpfNoNa) {
        events.push({name: 'no-' + val.name, from: 'dpf-' + val.name, to: 'oilchange-' + val.name})
      } else {
        events.push({name: 'no-' + val.name, from: 'dpf-' + val.name, to: 'oilyear-' + val.name})
      }
      events.push({name: 'standard-' + val.name, from: 'oilchange-' + val.name, to: 'oilyear-' + val.name})

      if (val.oilYop1995) {
        events.push({name: 'oilyopb1995-' + val.name, from: 'oilyear-' + val.name, to: val.oilYop1995})
      }
      if (val.oilYopa1995) {
        events.push({name: 'oilyopa1995-' + val.name, from: 'oilyear-' + val.name, to: val.oilYopa1995})
      }
      if (val.oilYop2004) {
        events.push({name: 'oilyop2004-' + val.name, from: 'oilyear-' + val.name, to: val.oilYop2004})
      }
      if (val.oilYopa2004) {
        events.push({name: 'oilyopa2004-' + val.name, from: 'oilyear-' + val.name, to: val.oilYopa2004})
      }
      if (val.oilDpfNoLonglife) {
        events.push({name: 'oillonglife-' + val.name, from: 'oilchange-' + val.name, to: val.oilDpfNoLonglife})
      }
      if (val.oilDpfNoNa) {
        events.push({name: 'oilna-' + val.name, from: 'oilchange-' + val.name, to: val.oilDpfNoNa})
      }
      if (val.oilDpfYes) {
        events.push({name: 'yes-' + val.name, from: 'dpf-' + val.name, to: val.oilDpfYes})
      }
      if (val.oilDpfNa) {
        events.push({name: 'na-' + val.name, from: 'dpf-' + val.name, to: val.oilDpfNa})
      }
  })
  events.push({name: 'cars', from: [], to: 'cars'})
  return StateMachine.create({
    initial: 'cars',
    events: events,
    current: 'cars'
  })
}

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  chooseCarWithLetter: ['letter'],
  startStateMachine: null,
  backStateMachine: null,
  changeState: ['key'],
  onChangeRedirectUrl: ['nextState'],
  showModal: null,
  getProductInfo: ['data']
})

export const StateMachineTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({ // eslint-disable-line
  stateMachine: null,
  letter: '',
  breadCrumb: [],
  nextState: '',
  products: [],
  mostUsedCar: ['Ford', 'Hyundai', 'Kia','Peugeot','Renault','Seat','Škoda','Volkswagen'],
  modal: false,
  productInfo: {}
})

/* ------------- Reducers ------------- */


export const chooseCarLetterR = (state, { letter }) =>
  state.merge({ letter })

export const startStateMachineR = state =>
  state.merge({ stateMachine: stateMachine()})

export const backStateMachineR = state => {
  const machine = stateMachine()
  let deleteOneCrumb = state.breadCrumb.asMutable()
  let to = deleteOneCrumb[deleteOneCrumb.length - 1]
    ? deleteOneCrumb[deleteOneCrumb.length - 1].name.split('-')[0]
    : ''
  deleteOneCrumb.splice(-1)
  deleteOneCrumb.map(st => {
    machine[st.value]()
  })
  return state.merge({
    stateMachine: machine,
    breadCrumb: deleteOneCrumb,
    nextState: to
  })
}

export const changeStateR = (state, { key }) => {
  const machine = state.stateMachine.asMutable()
  let to = machine.getTo(key)
  const bCrumb = [...state.breadCrumb]
  let products = []
  if (typeof(to) === 'string') {
    to = to.split('-')
    to = to[0]
  } else {
    products = to
    to = 'product'
  }
  machine[key]()
  bCrumb.push({
    name: machine.getFrom(key),
    value: key,
  })
  return state.merge({
    stateMachine: machine,
    breadCrumb: bCrumb,
    nextState: to,
    products,
  })
}

export const nextStateR = (state, { nextState }) =>
  state.merge({ nextState })

export const showModalR = state =>
  state.merge({ modal: !state.modal })

export const  getProductInfoR = (state, { data }) =>
  state.merge({ productInfo: data })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.CHOOSE_CAR_WITH_LETTER]: chooseCarLetterR,
	[Types.START_STATE_MACHINE]: startStateMachineR,
	[Types.BACK_STATE_MACHINE]: backStateMachineR,
	[Types.CHANGE_STATE]: changeStateR,
	[Types.ON_CHANGE_REDIRECT_URL]: nextStateR,
	[Types.SHOW_MODAL]: showModalR,
	[Types.GET_PRODUCT_INFO]: getProductInfoR,
})
