// @flow
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'

import { PersistGate } from 'redux-persist/es/integration/react'
import { persistStore } from 'redux-persist'

import RootContainer from './RootContainer'

// types
type Props = {
	store: any,
}

export default class AppContainer extends React.Component<Props> {
  shouldComponentUpdate () {
    return false
  }

  render () {
    const { store } = this.props
		let persistor = persistStore(store)

		return (
			<Provider store={store}>
				<div style={{ height: '100%' }}>
					<PersistGate persistor={persistor}>
						<BrowserRouter basename='/'>
							<Route component={RootContainer} path='/' />
						</BrowserRouter>
					</PersistGate>
				</div>
			</Provider>
		)
  }
}
