// @flow
import React, { Component} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// external libs
import I18n from 'i18n-react'

// component
import ModalAdmin from '../Components/ModalAdmin'

// redux
import AdminActions from '../Redux/AdminRedux'

// themes
import { Images } from '../Themes'

// styles
import './Styles/HomepageScreenStyles.scss'

class HomepageScreen extends Component<null, null> {

  handleOpenLogin = () => { this.props.openModal() }
  handleCloseModalLogin = () => { this.props.closeModal() }

  handleChange = (event) =>  {
    this.props.changePassword(event.target.value)
  }

  handleSubmit = (event) => {
    if (this.props.password === '1') {
      this.props.login()
    } else {
      this.props.loginFailure({ wrongPassword: 'error.wrongPassword' })
    }
    event.preventDefault()
  }

  handleChangeLanguage = lang => { this.props.setLanguage(lang) }

  handleChangePlace = place =>   { this.props.changePlace(place) }

  render() {
    return (
      <div className='home-page'>
        <div className='login-button' onClick={this.handleOpenLogin}/>
        <div className='logo-wrapper'>
          <img className='shell-logo' src={Images.mainLogo} />
        </div>
        <div className='text-wrapper'>
          <h5 className='selectOil'>{I18n.translate('homepage.text')}</h5>
        </div>
        <div className='button-wrapper'>
          <Link className='none-underline' to='/cars'>
            <div className='btn btn-danger'>
              <div className='btn-text'>{I18n.translate('_general.continue')}</div>
              <img className='btn-arrow' src={Images.rightIcon} />
            </div>
          </Link>
        </div>
        <ModalAdmin
          admin={this.props.admin}
          closeModal={this.handleCloseModalLogin}
          language={this.props.language}
          login={this.props.modalLogin}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          place={this.props.place}
          selectPlace={this.handleChangePlace}
          setLanguage={this.handleChangeLanguage}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.admin
})

const mapDispatchToProps = {
  ...AdminActions
}

export default connect(mapStateToProps, mapDispatchToProps)(HomepageScreen)
