// @flow
import React from 'react'
import { connect } from 'react-redux'

// external libs
import I18n from 'i18n-react'

// redux
import GeneralActions from '../Redux/GeneralRedux'

// themes
import { Images } from '../Themes'

// styles
import './Styles/QueryScreenStyles.scss'

type Props = {
  resetAll: ()=> void,
  history: Object
}

class QueryScreen extends React.Component<Props, null> {

  handleResetAll = () => {
    this.props.resetAll()
    this.props.history.push('/')
  }

  render () {
    return (
      <div className='query-page'>
       <div className='container'>
         <div className='thanks-wrapper'>
           <h4 className='title-thanks'>{I18n.translate('_general.help')}</h4>
           <div className='button-wrapper'>
             <div className='btn btn-secondary' onClick={this.handleResetAll}>
               <span className='btn-text'>{I18n.translate('_general.yes')}</span>
             </div>
             <div className='btn btn-secondary' onClick={this.handleResetAll}>
               <span className='btn-text'>{I18n.translate('_general.no')}</span>
             </div>
           </div>
           <span className='thanks'>{I18n.translate('_general.thank')}</span>
         </div>
         <div className='automax-wrapper'>
           <img className='automax-shell' src={Images.autoMax} />
         </div>
         <div className='product-made'>
           <h3>{I18n.translate('_general.sponzor')}</h3>
         </div>
       </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  resetAll: GeneralActions.resetAll,
}

export default connect(null, mapDispatchToProps)(QueryScreen)
