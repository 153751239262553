
const images = {
  // icons
  iconSih: require('../Images/icons/shell_logo_2x_SiH_icon.ico'),
  iconUgg: require('../Images/icons/shell_logo_2x_ugg_icon.ico'),
  icon32: require('../Images/icons/shell_logo_32x32.ico'),
  icon48: require('../Images/icons/shell_logo_48x48.ico'),
  icon64: require('../Images/icons/shell_logo_64x64.ico'),
  icon96: require('../Images/icons/shell_logo_96x96.ico'),
  icon192: require('../Images/icons/shell_logo_192x192.ico'),
  icon256: require('../Images/icons/shell_logo_256x256.ico'),

  mainLogo: require('../Images/shell_logo@2x.png'),
  mozaika: require('../Images/mozaika@2x.png'),
  rightIcon: require('../Images/ic_right_arrow@2x.png'),
  closeButton: require('../Images/cross@2x.png'),
  autoMax: require('../Images/automaxshell.jpg'),
  arrowContinue: require('../Images/icon_arrow_right@2x.png'),
  arrowBack: require('../Images/icon_arrow_left@2x.png'),
  done: require('../Images/ic_Round_right_arrow@2x.png'),
  plus: require('../Images/pureplus.png'),

  //car
  AlfaRomeo: require('../Images/alfaromeo@2x.png'),
  Audi: require('../Images/audi@2x.png'),
  BMW: require('../Images/bmw@2x.png'),
  Citroen: require('../Images/citroen@2x.png'),
  Dacia: require('../Images/dacia@2x.png'),
  Daewoo: require('../Images/daewoo@2x.png'),
  Dodge: require('../Images/dodge@2x.png'),
  Fiat: require('../Images/fiat@2x.png'),
  Ford: require('../Images/ford@2x.png'),
  Honda: require('../Images/honda@2x.png'),
  Hyundai: require('../Images/hyundai@2x.png'),
  Chevrolet: require('../Images/chevrolet@2x.png'),
  Jeep: require('../Images/jeep@2x.png'),
  Kia: require('../Images/kia@2x.png'),
  Lancia: require('../Images/lancia@2x.png'),
  LandRover: require('../Images/landrover@2x.png'),
  Mazda: require('../Images/mazda@2x.png'),
  Mercedes: require('../Images/mercedes@2x.png'),
  Mini: require('../Images/mini@2x.png'),
  Mitsubishi: require('../Images/mitsubishi@2x.png'),
  Nissan: require('../Images/nissan@2x.png'),
  Opel: require('../Images/opel@2x.png'),
  Peugeot: require('../Images/peugeot@2x.png'),
  Porsche: require('../Images/porsche@2x.png'),
  Renault: require('../Images/renault@2x.png'),
  Seat: require('../Images/seat@2x.png'),
  Smart: require('../Images/smart@2x.png'),
  Subaru: require('../Images/subaru@2x.png'),
  Suzuki: require('../Images/suzuki@2x.png'),
  Škoda: require('../Images/skoda@2x.png'),
  Toyota: require('../Images/toyota@2x.png'),
  Volkswagen: require('../Images/volkswagen@2x.png'),
  Volvo: require('../Images/volvo.png'),

  //products
  helixHX5: require('../Images/product/1L_Helix_HX5_15W_40_high.jpg'),
  helixHX6: require('../Images/product/1L_Helix_HX6_10W_40_high.jpg'),
  helixHX7: require('../Images/product/1L_Helix_HX7_5W_40_high.jpg'),
  helixHX710: require('../Images/product/1L_Helix_HX7_10W_40_high.jpg'),
  helixHX8: require('../Images/product/1L_Helix_HX8_ECT_5W_30_high.jpg'),
  helixHX8Syn: require('../Images/product/1L_Helix_HX8_Syn_5W_40_high.jpg'),
  helix5W30: require('../Images/product/1L_Helix_Ultra_5W_30_high.jpg'),
  helix5W40: require('../Images/product/1L_Helix_Ultra_5W_40_high.jpg'),
  helixECTC2: require('../Images/product/1L_Helix_Ultra_ECT_C2_C3_0W_30_RGB_DA_high.jpg'),
  helixECTC3: require('../Images/product/1L_Helix_Ultra_ECT_C3_5W_30_Dexos_FOP_high.jpg'),
  helixProAf: require('../Images/product/1L_HELIX_ULTRA_PRO_AF_5W_30_high.jpg'),
  helixProAfl: require('../Images/product/1L_HELIX_ULTRA_PRO_AFL_5W_30_high.jpg'),
  helixProAg: require('../Images/product/1L_Helix_Ultra_PRO_AG_5W_30_high.jpg'),
  helixProApl: require('../Images/product/1L_Helix_Ultra_PRO_APL_5W_30_high.jpg'),
  helixProArl: require('../Images/product/1L_Helix_Ultra_PRO_ARL_5W_30_high.jpg'),
  helixProAvl: require('../Images/product/1L_Helix_Ultra_Pro_AVL_0W_30_high.jpg'),
  helixProAbL: require('../Images/product/1L_Helix_Ultra_Professional_AB-L_0W_30_high (1).jpg'),
  helixProUltraAf: require('../Images/product/1L_Helix_Ultra_Professional_AF_5W_20_high.jpg'),
  helixApL: require('../Images/product/1L_Helix_Ultra_Professional_AP-L_0W_30_high.jpg'),
  helixSN: require('../Images/product/1L_Helix_Ultra_SN_0W_20_high.jpg'),
  helixHX7AV: require('../Images/product/1L_Shell_Helix_HX7_Professional_AV_5W_30_high.jpg'),
  helix5W: require('../Images/product/4L_Helix_Ultra_5W_30.jpg'),
}

export default images
