// @flow
import React from 'react'
import { connect } from 'react-redux'

// external libs
import I18n from 'i18n-react'

// redux
import StateMachineActions from '../Redux/StateMachineRedux'
import GeneralActions from '../Redux/GeneralRedux'


// styles
import './Styles/FilterScreenStyles.scss'

type Props = {
  backStateMachine: () => void,
  breadCrumb: Array<>,
  changeState: (string) => void,
  letter: string,
  stateMachine: Object,
}

class FilterScreen extends React.Component<Props> {

  goToNextPage = (car) => this.props.changeState(car)

  handleBack = () => {
    if(window.location.pathname === '/fuel') {
      this.props.resetAll()
      this.props.history.push('/cars')
    }
    this.props.backStateMachine()
  }
  render () {
    const transitions = this.props.stateMachine.transitions() || []
    return (
      <div className='brand-page'>
        <div className='container'>
          <div className='about-screen'>
            <div className='breadcrumb'>{this.props.breadCrumb.map(value =>{
              return `${I18n.translate(`_general.${value.name.split('-')[0]}`)}: ${I18n.translate(`_general.${value.value.split('-')[0]}`)}`
            }).join(' > ')}</div>
          </div>
          <div className='button-wrapper-filter'>
            <div className='filter-wrapper'>
              {transitions.map(button =>
                <div className='margin-button' key={button}>
                  <div className='btn btn-secondary' onClick={() => { this.goToNextPage(button) }}>
                  <span className='btn-text'>
                    {I18n.translate(`_general.${button.split('-')[0]}`)}
                  </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='back-button'>
            <div className='btn btn-primary arrow-back' onClick={this.handleBack}>
              <span className='btn-text'>{I18n.translate('_general.back')}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  stateMachine: state.machine.stateMachine,
  letter: state.machine.letter,
  breadCrumb: state.machine.breadCrumb,
})

const mapDispatchToProps = {
  changeState: StateMachineActions.changeState,
  resetAll: GeneralActions.resetAll,
  backStateMachine: StateMachineActions.backStateMachine,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterScreen)
