// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'


// external libs
import I18n from 'i18n-react'

// services
import products from '../Services/product.json'

// redux
import StateMachineActions from '../Redux/StateMachineRedux'

// styles
import './Styles/ListCarScreenStyles.scss'
import {Images} from '../Themes'

type Props = {
  chooseCarWithLetter: string,
  history: Object,
  backStateMachine: () => void,
}

class ListCarScreen extends React.Component<Props, null> {



  renderAlphabet = () => {
    const letters = products.reduce((acc, val) => {
      acc.indexOf(val.name.charAt(0)) === -1 ? acc.push(val.name.charAt(0)) : null
      return acc
    }, [])
    const Alphabet = []
    for (let c = 65; c <= 90; c++) {
      const alpha = String.fromCharCode(c)
      Alphabet.push(
        <div
          className='col-25'
          key={c}
          onClick={() => { this.handleLetter(alpha, letters) }}
        >
          <h1 className={letters.indexOf(alpha) !== -1 ? 'primary' : 'disabled'}>
            {alpha}
          </h1>
        </div>
      )
    }
    return Alphabet
  }

  searchCar = (letter) => {
    this.props.chooseCarWithLetter(letter)
    this.props.history.push('cars')
  }

  handleLetter = (alpha, letters) => {
    if (letters.indexOf(alpha) !== -1) {
      this.searchCar(alpha)
    }
  }


  handleBack = () => this.props.backStateMachine()

  render () {
    return (
      <div className='list-page'>
        <div className='container'>
          <div className='alpha-table'>
            <div className='row'>
              {this.renderAlphabet()}
            </div>
          </div>
          <Link className='none-underline' to='/cars'>
            <div className='back-button'>
              <div className='btn btn-primary arrow-back' onClick={this.handleBack}>
                <span className='btn-text'>{I18n.translate('_general.back')}</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  chooseCarWithLetter: StateMachineActions.chooseCarWithLetter,
  backStateMachine: StateMachineActions.backStateMachine,
}

export default connect(null, mapDispatchToProps)(ListCarScreen)
